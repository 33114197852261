<template>
    <div>
        <b-overlay :opacity="overlay.opacity" :show="overlay.visible" rounded="sm">
            <b-container class="mt-3" fluid>
                <!-- table toolbar -->
                <table-toolbar
                    :locale-key="localeKey"
                    :title="$t('business.acceptance_form')"
                    class="bg-primary p-1"
                    style="height: 45px"
                ></table-toolbar>

                <!-- table -->
                <b-table
                    striped
                    outlined
                    hover
                    bordered
                    responsive="sm"
                    :fields="columns"
                    :items="items"
                    thead-tr-class="text-center bg-secondary"
                    table-class="table-pnd"
                >

                    <!-- actions -->
                    <template v-slot:cell(action)="row">
                        <div>
                            <b-icon-trash
                                style="cursor: pointer;"
                                variant="danger"
                                scale="1.3"
                                v-b-tooltip.hover
                                :title="$t('table.remove')"
                                @click="removeForm(row)"
                            ></b-icon-trash>
                        </div>
                    </template>

                    <template v-slot:cell(edit)="row">
                        <b-icon-file-text
                            size="sm"
                            style="cursor: pointer;"
                            variant="secondary"
                            scale="1.3"
                            v-b-tooltip.hover
                            class="align-middle"
                            @click="recordEdit(row.item)"
                        >
                        </b-icon-file-text>
                        <ModalFormAcceptanceForm></ModalFormAcceptanceForm>
                    </template>

                </b-table>
                <!-- pagination -->
                <b-pagination
                    aria-controls="main-table"
                    limit="20"
                    size="sm"
                ></b-pagination>
            </b-container>
        </b-overlay>

        <!-- modalForm -->
        <ModalFormAcceptanceForm
            :locale-key="localeKey"
        ></ModalFormAcceptanceForm>
    </div>
</template>

<script>
import ModalFormAcceptanceForm from "@/components/business/acceptanceForm/ModalFormAcceptanceForm";
import TableToolbar from "@/components/general/elements/tableAccessories/TableToolbar";

export default {
    name: "AcceptanceForms",
    components: {TableToolbar, ModalFormAcceptanceForm},
    props: {},
    data() {
        return {
            overlay: {
                visible: false,
                opacity: 0.8,
            },
            localeKey: 'business.acceptance_form.main',
            selected: '',
            columns: [
                {
                    key: 'name',
                    label: this.$t('organisation.name'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'description',
                    label: this.$t('organisation.contact_description'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'phone',
                    label: this.$t('organisation.phone_default'),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'certificate',
                    label: this.$tc('organisation.certificate', 2),
                    sortable: true,
                    show: true,
                    thStyle: {'vertical-align': 'middle'},
                    tdClass: 'align-middle',
                },
                {
                    key: 'edit',
                    label: this.$t('form.edit'),
                    sortable: false,
                    show: true,
                    thStyle: {'vertical-align': 'middle'}
                },
                {
                    key: 'action',
                    label: '',
                    thStyle: {'vertical-align': 'middle', width: '55px'},
                    tdClass: 'align-middle',
                    tdStyle: {'vertical-align': 'middle'}
                },
            ],
            items: [
                {
                    id:1,
                    name: 'Kmetija Bolfenk',
                    description: 'Big Boss',
                    tourism: null,
                    phone: '0038641555555',
                    certificate: 'Ekološka kmetija',
                },
                // {
                //     id:2,
                //     name: 'Kmetija Bolfenk',
                //     description: 'Big Boss',
                //     tourism: null,
                //     phone: '0038641555555',
                //     certificate: 'Ekološka kmetija',
                // },
                // {
                //     id:3,
                //     name: 'Kmetija Bolfenk',
                //     description: 'Big Boss',
                //     tourism: null,
                //     phone: '0038641555555',
                //     certificate: 'Ekološka kmetija',
                // },
                // {
                //     id:4,
                //     name: 'Kmetija Bolfenk',
                //     description: 'Big Boss',
                //     tourism: null,
                //     phone: '0038641555555',
                //     certificate: 'Ekološka kmetija',
                // },
                // {
                //     id:5,
                //     name: 'Kmetija Bolfenk',
                //     description: 'Big Boss',
                //     tourism: null,
                //     phone: '0038641555555',
                //     certificate: 'Ekološka kmetija',
                // },
            ],
            table: {
                items: [],
                fields: [],
                sortBy: 'id',
                sortDesc: false,
            },
        }
    },
    mounted() {
    },
    methods: {
        removeForm(row) {
        },
        recordEdit(row) {
            this.$bvModal.show('ModalFormAcceptanceForm');
        },
    },
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>
.table-pnd {
    background-color: #D1E4A0;
}
</style>
