<template>
    <div>
        <b-row class="table-toolbar p-1 pr-3">
            <!--  title  -->
            <b-col class="" cols="auto">
                <h4>{{$tc(localeKey, 1)}}</h4>
            </b-col>
            <!-- number of shown/found items-->
            <b-col class="mr-auto ml-0 px-0" cols="auto">
                <b-button-group size="sm">
                    <b-button disabled variant="transparent"><b>({{options.filterCount}}/{{options.rowsCount}})</b>
                    </b-button>
                </b-button-group>
            </b-col>
            <!--  columns show  -->
            <b-col v-if="!__isNull(columnsShow)" class="px-0 mb-2" cols="auto">
                <b-dropdown id="dropdown-table-columns-show"
                            ref="dropdownTableColumnsShow"
                            :style="{'width': columnsShowWidth}"
                            :text="$t('table.select_columns')"
                            class="mx-1"
                            right
                            size="sm"
                >
                    <div :style="{'width': columnsShowWidth}">
                        <b-form-checkbox-group
                            v-model="response.columnsShow"
                            :options="getColumnsShow"
                            class="mb-0 pl-3 pt-2 pb-1"
                            size="sm"
                            stacked
                            text-field="label"
                            value-field="key"
                        ></b-form-checkbox-group>
                        <b-dropdown-divider class="mx-2"></b-dropdown-divider>
                        <!-- show/clean all -->
                        <b-dropdown-header id="dropdown-scope-header">
                            <b-button size="sm" style="float: left" variant="primary" @click="responseColumnShowAll">
                                {{$t('table.select_all')}}
                            </b-button>
                            <b-button size="sm" style="float: right" variant="primary" @click="responseColumnCleanAll">
                                {{$t('table.clean_all')}}
                            </b-button>
                        </b-dropdown-header>
                    </div>
                </b-dropdown>
            </b-col>
            <!--  filter (search + columnsOn)  -->
            <b-col class="px-0 mb-2" cols="auto">
                <div :style="{width: filterWidth}">
                    <b-input-group :style="{width: filterWidth}" size="sm">
                        <b-form-input
                            id="filter-search"
                            v-model="filter.search"
                            :placeholder="$t('search.placeholder')"
                            type="search"
                        ></b-form-input>
                        <!-- inputGroupAppend -->
                        <b-input-group-append v-if="columnsFilter.length > 0">
                            <b-dropdown
                                id="dropdown-columns-filter"
                                no-caret
                                offset="1"
                                right
                                size="sm"
                                text="..."
                                @show="filterDropdownOpen()"
                            >
                                <div :style="{width: filterWidth}">
                                    <!-- header -->
                                    <b-dropdown-header
                                        id="dropdown-scope-header"
                                        class="ml-2"
                                    >
                                        {{$t('search.limits')}}
                                    </b-dropdown-header>
                                    <b-dropdown-divider class="mx-2"></b-dropdown-divider>
                                    <b-form-checkbox-group
                                        v-model="filter.columnsOn"
                                        :options="columnsFilter"
                                        class="mb-0 pl-3 pt-2 pb-1"
                                        stacked
                                        text-field="label"
                                        value-field="key"
                                    ></b-form-checkbox-group>

                                    <b-dropdown-divider class="mx-2"></b-dropdown-divider>
                                    <!-- select/clean all -->
                                    <b-dropdown-header id="dropdown-scope-header">
                                        <b-button size="sm" style="float: left" variant="link"
                                                  @click="responseFilterSelectAll">
                                            {{$t('table.select_all')}}
                                        </b-button>
                                        <b-button size="sm" style="float: right" variant="link"
                                                  @click="responseFilterCleanAll">
                                            {{$t('table.clean_all')}}
                                        </b-button>
                                    </b-dropdown-header>
                                </div>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </div>
            </b-col>
            <!--  per page, buttons  -->
            <b-col class="px-0 mb-2" cols="auto">
                <b-form-select
                    v-model="options.pagination.perPage"
                    :options="paginationPerPage"
                    class="mx-1"
                    size="sm"
                    style="width: 65px"
                ></b-form-select>

                <!--  buttons: refresh & new -->
                <b-button class="mx-1" size="sm" variant="secondary" @click="$emit('eventRefresh');">
                    <b-icon-arrow-repeat
                        scale="1.5"
                        variant="white"
                    ></b-icon-arrow-repeat>
                </b-button>
                <b-button class="mx-1" size="sm" variant="secondary" @click="$emit('eventNew');">
                    <b-icon-plus
                        scale="2"
                        variant="white"
                    ></b-icon-plus>
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>

export default {
    name: "TableToolbar",
    components: {},
    props: {
        localeKey: '',
        columnsShowWidth: {
            type: String,
            default: '200px'
        },
        filterWidth: {
            type: String,
            default: '250px'
        },
        columnsShow: {
            type: Array,
            default() {
                return [];
            }
        },
        columnsFilter: {
            type: Array,
            default() {
                return [];
            }
        },
        options: {
            type: Object,
            default() {
                return {
                    filterCount: 0,
                    rowsCount: 0,
                    pagination: {
                        perPage: 10,
                        currentPage: 1,
                    },
                }
            }
        },
        response: {
            type: Object,
            default() {
                return {
                    columnsShow: [],
                    filter: {}
                }
            }
        }
    },
    data() {
        return {
            filter: {
                search: null,
                columnsOn: null
            },
            paginationPerPage: [
                {value: '5', text: '5'},
                {value: '10', text: '10'},
                {value: '15', text: '15'},
                {value: '20', text: '20'},
                {value: '50', text: '50'},
                {value: '100', text: '100'},
                {value: '200', text: '200'},
            ],
        }
    },
    mounted() {
        this.responseColumnsShow();
    },
    methods: {
        // <-- columnsShow -->
        responseColumnsShow() {
            let arraySelected = [];
            this.columnsShow.forEach(column => {
                if (column.show) {
                    arraySelected.push(column.key)
                }
            });
            // <--  -->
            this.$set(this.response, 'columnsShow', arraySelected);
        },
        responseColumnShowAll() {
            let arraySelected = [];
            this.columnsShow.forEach(column => {
                arraySelected.push(column.key);
            });
            // <--  -->
            this.$set(this.response, 'columnsShow', arraySelected);
        },
        responseColumnCleanAll() {
            this.$set(this.response, 'columnsShow', []);
        },
        // <-- filter -->
        filterDropdownOpen() {
            if (this.filter.columnsOn === null) {
                this.filter.columnsOn = [];
                this.responseFilterSelectAll();
            }
        },
        responseFilter() {
            this.$set(this.response, 'filter', this.filter);
        },
        responseFilterSelectAll() {
            this.filter.columnsOn = [];
            this.columnsFilter.forEach(column => {
                this.filter.columnsOn.push(column.key);
            });
        },
        responseFilterCleanAll() {
            this.filter.columnsOn = [];
        },
    },
    computed: {
        getColumnsShow() {
            let array = [];
            this.columnsShow.forEach(column => {
                if (column.alwaysShow !== true) {
                    array.push(column);
                }
            });
            // <--  -->
            return array;
        }
    },
    filters: {},
    watch: {
        'columnsShow': {
            handler: function () {
                this.responseColumnsShow();
            },
            deep: true
        },
        'columnsFilter': {
            handler: function () {
                this.responseFilter();
            },
            deep: true
        },
        'filter': {
            handler: function () {
                this.responseFilter();
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.dropdown-header {
    padding: 5px !important;
    min-width: 180px !important;
}
</style>
