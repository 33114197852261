<template>
    <b-modal
        :id="$options.name"
        :cancel-title="$t('form.cancel')"
        :ok-title="$t('form.save')"
        :title="title"
        header-bg-variant="primary"
        size="xl"
    >
<!--        @ok="formSave"-->
<!--        @show="modalOpen"-->
        : {{$options.name}}
    </b-modal>
</template>

<script>
export default {
    name: "ModalFormAcceptanceForm",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {
        title: {
            get() {
                return "neli ima ušesa"
                // let record = this.record;
                // if (this.form.id <= 0 || record === null || record.name === undefined) {
                //     return this.$t('form.add') + ': ' + this.$tc(this.localekey, 0).tolowercase();
                // }
                // // <-- name -->
                // let name = record.name.name;
                // // <--  -->
                // return this.$tc(this.localekey, 0) + ': ' + name;
            }
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
